<div class="particles-container">
  <ngx-particles
      [id]="id"
      [options]="particlesOptions"
      [particlesInit]="particlesInit"
      (particlesLoaded)="particlesLoaded($event)"
  ></ngx-particles>
</div>

<!-- Toolbar -->
<mat-toolbar color="primary">
  <img src="assets/cropped-logo.png" alt="App Logo" class="app-logo">
  <span>Slate</span>
  <span class="spacer"></span>
  <nav>
    <div class="get-started-wrapper" #getStartedWrapper>
      <a [href]="mainAppUrl + '/login'" class="get-started-button" color="accent">Get Started</a>
    </div>
  </nav>  
</mat-toolbar>

<!-- Hero Section with Swiper Carousel -->
<section class="hero">
  <div class="swiper mySwiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="slide-content">
          <h1>Unlock advanced reasoning with AI</h1>
          <p>Slate is an AI-powered workspace that lets you access GPT-4 across your knowledge bases.</p>
          <button mat-raised-button color="accent">Start Your Free Trial</button>
        </div>
      </div>
      <div class="swiper-slide">
        <div class="slide-content">
          <h1>Another Slide Title</h1>
          <p>Another description for this slide.</p>
          <button mat-raised-button color="accent">Learn More</button>
        </div>
      </div>
      <!-- Add more slides as needed -->
    </div>
    <!-- Swiper Pagination -->
    <div class="swiper-pagination"></div>
    
    <!-- Swiper Navigation Buttons Positioned at the Bottom of the Swiper -->
    <div class="swiper-controls-bottom">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</section>

<!-- Features Section -->
<section id="features" class="features">
  <h2>Slate's AI-Powered Features</h2>
  <mat-grid-list [cols]="cols" rowHeight="350px" gutterSize="20px">
    <mat-grid-tile *ngFor="let feature of features">
      <mat-card class="feature-card">
        <mat-icon>{{ feature.icon }}</mat-icon>
        <h3>{{ feature.title }}</h3>
        <p>{{ feature.description }}</p>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</section>
