export function drawLine(data) {
  const {
      context,
      particle,
      radius
    } = data,
    shapeData = particle.shapeData,
    centerY = 0;
  context.moveTo(-radius, centerY);
  context.lineTo(radius, centerY);
  context.lineCap = shapeData?.cap ?? "butt";
}