// landing-page.component.ts

import { Component, HostListener, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Engine, Container, RecursivePartial, IOptions } from '@tsparticles/engine';
import { loadFull } from 'tsparticles'; // Import loadFull instead of loadSlim
import { AfterViewInit } from '@angular/core';
import { environment } from '../../environments/environment';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class LandingPageComponent implements OnInit {
  id = "tsparticles"; // Unique ID for the particles instance
  mainAppUrl = environment.mainAppUrl;
  cols = 3;
  @ViewChild('getStartedWrapper') getStartedWrapper!: ElementRef;
  features = [
    {
      icon: 'task',
      title: 'AI Task Breakdown',
      description: 'Transform complex projects into actionable steps with our AI-driven task generator.'
    },
    {
      icon: 'layers',
      title: 'Unlimited Nested Projects',
      description: 'Create sophisticated project hierarchies with unlimited sub-tasks and nested projects.'
    },
    {
      icon: 'timer',
      title: 'Smart Time Tracking',
      description: 'Effortlessly track time and visualize productivity with intuitive charts and reports.'
    }
    // Add more features as needed
  ];
  particlesOptions: RecursivePartial<IOptions> = {
    background: {
      color: { value: "transparent" }
    },
    fpsLimit: 90,
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "grab" as const // Using const string literal
        },
        onClick: {
          enable: true,
          mode: "push" as const // Using const string literal
        },
        resize: { // Fixing type here by making it an object
          enable: true
        }
      },
      modes: {
        grab: {
          distance: 200,
          links: {
            opacity: 0.5
          }
        },
        push: {
          quantity: 4
        },
        repulse: {
          distance: 200,
          duration: 0.4
        }
      }
    },
    particles: {
      color: {
        value: ["#1e90ff", "#32cd32", "#ffffff"]
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.2,
        width: 2
      },
      move: {
        direction: "none" as const, // Using const string literal
        enable: true,
        outModes: {
          default: "bounce" as const // Using const string literal
        },
        speed: { min: 0.5, max: 2 },
        attract: {
          enable: true,
          rotate: {
            x: 600, // Corrected 'rotateX' to 'rotate.x'
            y: 1200 // Corrected 'rotateY' to 'rotate.y'
          }
        }
      },
      number: {
        density: {
          enable: true,
          width: 800, // Replaced 'area' with 'width' and 'height'
          height: 800
        },
        value: 30
      },
      opacity: {
        value: 0.05,
        animation: {
          enable: true,
          speed: 0.5,  // Adjust the speed for smooth transitions
          sync: false
        }
      },
      shape: {
        type: "circle" as const // Using const string literal
      },
      size: {
        value: 2,
        animation: {
          enable: true,
          speed: 2,  // Adjust the speed for smooth scaling
          sync: false
        }
      }
    },
    detectRetina: true
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setColsBasedOnWidth();
  }

  ngOnInit() {
    this.setColsBasedOnWidth();

    // Initialize Swiper after a slight delay to ensure the view is ready
    
  }
  ngAfterViewInit(): void {
    // Initialize Swiper
    new Swiper('.swiper', {
      modules: [Navigation, Pagination], // Add the modules explicitly here
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: true,
    });
    this.startAnimation();
  }
  async particlesInit(engine: Engine): Promise<void> {
    await loadFull(engine);
  }
particlesLoaded(container: Container): void {
  console.log(container); // This should log the container when particles are loaded
}

  setColsBasedOnWidth() {
    this.cols = window.innerWidth <= 960 ? 1 : 3;
  }
  private startAnimation(): void {
    // Ensure the wrapper is available
    if (!this.getStartedWrapper) {
      return;
    }

    const wrapper = this.getStartedWrapper.nativeElement;
    let r2 = 0;
    let x = 0;

    const animate = () => {
      // Update the angle and position
      r2 = (r2 + 1) % 360; // Increment `r2` from 0 to 360 and then reset
      x = (x + 0.5) % 100; // Increment `x` from 0 to 100 and then reset

      // Apply the updated CSS variables directly to the element's style
      wrapper.style.setProperty('--r2', `${r2}deg`);
      wrapper.style.setProperty('--x', `${x}%`);

      // Continue the animation
      requestAnimationFrame(animate);
    };

    // Start the animation
    requestAnimationFrame(animate);
  }
}
