import { BubbleBase } from "./BubbleBase.js";
export class BubbleDiv extends BubbleBase {
  constructor() {
    super();
    this.selectors = [];
  }
  load(data) {
    super.load(data);
    if (!data) {
      return;
    }
    if (data.selectors !== undefined) {
      this.selectors = data.selectors;
    }
  }
}